<template>
  <div class="demand-detail">
    <div class="dd-body">
      <div class="dd-body-left">
        <div class="ddbl-title ellipse-1">{{detailInfo&&detailInfo.title}}</div>
        <div class="ddbl-des">
          <span>预算金额：</span>
          <span style="color:#1890ff ">协商确定</span>
          <span style="margin-left: 30px">需求类型：{{detailInfo&&detailInfo.requirementTypeName}}</span>
          <span style="margin-left: 30px">需求日期：{{detailInfo&&detailInfo.updatedTime}}</span>
        </div>
        <div class="ddbl-content">
          {{detailInfo&&detailInfo.content}}
        </div>
      </div>
      <div class="dd-body-right">
        <div class="ddbr-title">最新需求</div>
        <div style="padding: 0 15px">
          <div
            @click="getDemandDetail(item.id)"
            v-for="item in newestList" :key="item.id"
            style="margin-top: 14px;border: 1px solid  rgba(220, 220, 220, .5);height: auto;cursor: pointer">
            <span class="xmsb_circle"
                  style=" vertical-align:top;top:-4px;position:relative;padding-top:5px;padding-left:5px;color:white;min-height: 50px;width: 40px;background-color:#1890ff;display: inline-block;margin-left: 10px;font-size: 14px">
                {{item.requirementTypeName}}
              </span>
            <div style="width: 240px;height: 100%;padding: 10px;display: inline-block">
              <div class="xqmc ellipse-2">
                <span>{{item.title}}</span></div>
              <div class="ellipse-2" style="margin-top: 10px;margin-bottom: 20px">
                {{item.content}}
              </div>
              <div style="font-size: 14px"><span>预算金额：</span><span style="color: #1890ff">协商确定</span></div>
              <div style="font-size: 14px"><span>需求日期：{{item.updatedTime.split(" ")[0]}}</span></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getDemandDetailById, getDemandListByType} from "../../plugins/api/demandApi";

  export default {
    name: 'demandDetail',
    data() {
      return {
        detailInfo: {},//详情信息
        newestList: [],//最新需求列表
      }
    },
    mounted() {
      this.getDemandDetail(this.$route.query.id);
      this.getNewestDemandList();
    },
    methods: {
      //获取需求详情
      async getDemandDetail(id) {
        const params = {
          requirementId: id
        }
        const json = await getDemandDetailById(params);
        if (json && json.code === 0) {
          this.detailInfo = json.result
        }
      },
      async getNewestDemandList() {
        const params = {
          pageSize: 3,
          pageNum: 1
        }
        const json = await getDemandListByType(params);
        if (json && json.code === 0) {
          if (json.result && json.result.list && json.result.list.length > 0) {
            this.newestList = json.result.list

          }
        }
      }
    }
  }
</script>

<style scoped lang="css">
  .demand-detail {
    background-color: #FAFAFA;
    display: flex;
    flex-direction: row;
    padding-top: 40px;
    justify-content: center;
    padding-bottom: 40px;
  }

  .dd-body {
    display: flex;
    flex-direction: row;
  }

  .dd-body-left {
    padding: 40px 40px 80px 40px;
    width: 840px;
    background-color: #FFFFFF;
  }

  .ddbl-title {
    font-size: 18px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #333333;

  }

  .ddbl-des {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
  }

  .ddbl-content {
    font-size: 14px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #333333;
    margin-top: 20px;
  }

  .ddbl-html >>> img {
    display: block;
    margin: 0 auto;
    max-width: 100% !important;
    height: auto !important;
  }

  .ddbl-des span {
    font-size: 12px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #666666;
  }

  .dd-body-right {
    width: 330px;
    margin-left: 25px;
    background-color: #FFFFFF;
    height: fit-content;
    padding-bottom: 30px;
  }

  .ddbr-title {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    font-size: 16px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #333333;
    border-bottom: 1px solid #E8E8E8;
  }

  .xqmc {
    font-size: 14px;
    color: black;
  }

  .xmsb_circle:after {
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    top: 0;
    left: 39px;
    background-color: #1890ff;
    border-radius: 0 4px 0 0;
  }
</style>
