//获取需求类型
import {get} from "../http/http";
import baseUrl from "../http/baseUrl";

//获取需求类型
export const getDemandTypeList = (params) => {
  return get(`${baseUrl}/v1/requirement/type`, params, {hideLoading: true});
}

//获取需求列表
export const getDemandListByType = (params) => {
  return get(`${baseUrl}/v1/requirement`, params, {hideLoading: true})
}

//根据需求ID获取需求详情
export const getDemandDetailById = (params) => {
  return get(`${baseUrl}/v1/requirement/detail`, params, {hideLoading: true});
}
